import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CssBaseline, ThemeProvider, Container } from "@mui/material";
import Slide from "@mui/material/Slide";
import { DialogContent } from "@mui/material";
import theme from "../../theme/theme";
import { appTheme } from "../../theme/colorTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FullLoginTermsConditionsDialogScreen = (props) => {
    const handleDialogClose = () => {
        props.setOpenTermsDialog(false);
    };
    React.useEffect(() => {



    }, [])

    return (
        <>
            <Dialog
                fullScreen
                open={props.openTermsDialog}
                sx={{ maxWidth: "600px", margin: '0 auto' }}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative", bgcolor: appTheme.color0 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleDialogClose()}
                            aria-label="close"
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="body1" component="div">
                            Terms & Conditions
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: appTheme.color0, padding: 0, pb: 1, }}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container sx={{
                            color: appTheme.color1,
                            mt: 2
                        }}>
                            <Typography variant="caption" component="div" color={appTheme.color1} >
                                Terms and Conditions agreements act as a legal contract between DubaiBaazi and the user who access your website and mobile app.
                                <br></br>
                                <br></br>
                                You agree that by accessing the App, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the App.
                                <br></br>
                                <br></br>
                                You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.
                                <br></br>
                                <br></br>
                                DubaiBaazi is intended for users who are at least 18 years old. Person under the age of 18 is not permitted to register for the App.
                                All the registration information you submit will be true, accurate, current, and complete.
                                You will not use the Site for any illegal or unauthorized purpose.
                                Your use of the Site will not violate any applicable law or regulation.
                                If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).
                            </Typography>
                        </Container></ThemeProvider>

                </DialogContent>
            </Dialog>
        </>
    );
};
const LoginTermsConditionsDailog = React.memo(FullLoginTermsConditionsDialogScreen);
export default LoginTermsConditionsDailog;
